import React from "react";
import type { HeadFC } from "gatsby"

import VerticalCenter from "../../components/VerticalCenter";
import Nav from "../../components/Nav";

import imgSingularity from "./images/singularity.png";

import "../../base.css"
import Footer from "../../components/Footer";

const pageStyles = {
    
}

const containerStyles: React.CSSProperties = {
    textAlign: 'left',
    display: 'flex',
    paddingBottom: "50px"
}

const projNameStyles: React.CSSProperties = {
    fontSize: "150%",
    fontWeight: "bold"
}

const projStackStyles: React.CSSProperties = {
    fontStyle: 'italic',
    fontSize: "90%",
    color: '#bbbbbb'
}

const projDescStyles: React.CSSProperties = {
    whiteSpace: 'pre-line'
}

const projImageContainerStyles: React.CSSProperties = {

}

const projImageStyles: React.CSSProperties = {
    maxWidth: '30vw',
    height: 'auto',
    display: 'block',
}

const projMetaStyles: React.CSSProperties = {
    paddingRight: '100px'
}


const projectsList = [
    {
        name: "Singularity",
        description: `Video Compression Tool \n
        Provides easy video compression with minimal computational load on the client-side.
        `,
        stack: "React (TypeScript), ASP.NET, Docker",
        image: imgSingularity,
        link: "https://github.com/joshlabue/singularity"
    },
/*    {
        name: "\"Macro Boi\"",
        description: `Programmable USB Macro Keyboard \n
        STM32 driven macro pad with LCD to indicate current selection, with buttons to cycle through available presets.
        `,
        stack: "C"
    },
    {
        name: "Onewheel Battery Monitor",
        description: `Android app to monitor the battery level of a Onewheel via BLE
        `,
        stack: "Android (Kotlin)",
        link: 'https://github.com/joshlabue/onewheel-battery-monitor'
    }
    */
]

const Projects = () => {
    return <main style={pageStyles}>
            <VerticalCenter>
                <Nav/>
                <div>
                    {
                        projectsList.map(project => {

                            let projectName = <span style={projNameStyles}>{project.name}</span>;

                            if(project.link !== undefined) projectName = <a style={projNameStyles} href={project.link}>{project.name}</a>

                            return <div style={containerStyles} key={project.name}>
                                <div style={projMetaStyles}>
                                    {projectName}
                                    <br />
                                    <span style={projDescStyles}>{project.description}</span>
                                    <br />
                                    {/* <span></span> */}
                                    <span style={projStackStyles}>Stack: {project.stack}</span>
                                </div>
                                <div style={projImageContainerStyles}>
                                    <a href={project.link}>
                                        <img style={projImageStyles} src={project.image}/>
                                    </a>
                                </div>
                            </div>
                        })
                    }
                </div>

                <Footer />
            </VerticalCenter>
        </main>
}

export default Projects;

export const Head: HeadFC = () => <title>Projects - Joshua LaBue</title>
